import { PageProps } from 'gatsby';
import React from "react"
import Lesson from "../../components/Lessons/Lesson";

const LessonPage = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <Lesson lessonId={props.params.lessonId} />
}

export default LessonPage
