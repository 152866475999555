import React from 'react'
import CreateLesson from '../CreateLesson'

const Lesson = (props) => {
  return <CreateLesson lessonId={props.lessonId} />
}

Lesson.propTypes = {

}

Lesson.defaultProps = {

}

export default Lesson
